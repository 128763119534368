import React from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Seo from "../seo/Seo";
import { NavLink } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";

const B2B = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings4 = {
    infinite: true,
    speed: 2000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    waitForAnimate: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const schema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "G-Rank",
        item: "https://www.grank.co.in/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "B2b Digital marketing agency",
        item: "https://www.grank.co.in/b2b-digital-marketing-agency",
      },
    ],
  };

  return (
    <>
      <Seo
        title="B2b digital marketing agency | India's Top Rated B2B Marketing Agency"
        description="B2B digital marketing agency G-Rank Digital Services facilitates B2B marketing that generates leads & revenue. Visit the website now."
        name="G-Rank"
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="b2b digital marketing agency bangalore,
        b2b digital marketing agency hyderabad,
        top b2b digital marketing agency in India"
        searchengines="ALL"
        canonical="https://www.grank.co.in/b2b-digital-marketing-agency"
        schema={schema}
      ></Seo>

      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/b2b/b2b-banner1.png"
            alt="G-Rank"
            title="b2b banner"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/b2b/b2b-banner1-mobile.png"
            alt="G-Rank"
            title="b2b banner"
          />

          <div className="b2b-banner-div">
            <motion.h1
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              B2B Digital
              <br />
              Marketing Agency
            </motion.h1>

            <motion.h3
              href="#"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">Get a Quote</NavLink>
            </motion.h3>
            <Breadcrumbs
              className="breadcrumbs"
              aria-label="breadcrumb"
              style={{ float: "right", paddingTop: "0px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#000",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>

              <Typography color="#000">B2B</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}

        {/* b2b second section */}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h2
                className="slider-heading"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                What we do
              </motion.h2>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-6 col-lg-2 b2b-section-second b2b-border1">
                  <div className="op3">
                    <img
                      src="assest/photos/b2b/icon-main/b2b-main-icon12.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <h2 className="b2b-slider-img5">
                      B2B <br />
                      Communication
                      <br /> Strategy
                    </h2>
                  </div>
                </div>
                <div className="col-6 col-lg-2 b2b-section-second b2b-border1">
                  <div className="op3">
                    <img
                      src="assest/photos/b2b/icon-main/b2b-main-icon11.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <h2 className="b2b-slider-img5">
                      Content
                      <br />
                      Creation
                    </h2>
                  </div>
                </div>
                <div className="col-6 col-lg-2  b2b-section-second b2b-border1">
                  <div className="op3">
                    <img
                      src="assest/photos/b2b/icon-main/b2b-main-icon10.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <h2 className="b2b-slider-img5">
                      Lead <br />
                      Generation
                    </h2>
                  </div>
                </div>
                <div className="col-6 col-lg-2  b2b-section-second b2b-border1">
                  <div className="op3">
                    <img
                      src="assest/photos/b2b/icon-main/b2b-main-icon9.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <h2 className="b2b-slider-img5">
                      Lead
                      <br /> Nurturing
                    </h2>
                  </div>
                </div>
                <div className="col-6 col-lg-2  b2b-section-second b2b-border1">
                  <div className="op3">
                    <img
                      src="assest/photos/b2b/icon-main/b2b-main-icon8.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <h2 className="b2b-slider-img5">
                      Sales <br />
                      Enablement
                    </h2>
                  </div>
                </div>
                <div className="col-6 col-lg-2  b2b-section-second b2b-border3">
                  <div className="op3">
                    <img
                      src="assest/photos/b2b/icon-main/b2b-main-icon7.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <h2 className="b2b-slider-img5">
                      Thought
                      <br /> Leadership
                    </h2>
                  </div>
                </div>
                <div className="col-6 col-lg-2  b2b-section-second b2b-border2">
                  <div className="op3">
                    <img
                      src="assest/photos/b2b/icon-main/b2b-main-icon6.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <h2 className="b2b-slider-img5">
                      Graphics <br />& Videos
                    </h2>
                  </div>
                </div>
                <div className="col-6 col-lg-2  b2b-section-second b2b-border2">
                  <div className="op3">
                    <img
                      src="assest/photos/b2b/icon-main/b2b-main-icon5.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <h2 className="b2b-slider-img5">
                      Corporate
                      <br /> Branding
                    </h2>
                  </div>
                </div>
                <div className="col-6 col-lg-2  b2b-section-second b2b-border2">
                  <div className="op3">
                    <img
                      src="assest/photos/b2b/icon-main/b2b-main-icon4.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <h2 className="b2b-slider-img5">
                      B2B <br />
                      Social Media
                    </h2>
                  </div>
                </div>
                <div className="col-6 col-lg-2  b2b-section-second b2b-border2">
                  <div className="op3">
                    <img
                      src="assest/photos/b2b/icon-main/b2b-main-icon3.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <h2 className="b2b-slider-img5">
                      Search & Display <br />
                      Marketing
                    </h2>
                  </div>
                </div>

                <div className="col-6 col-lg-2  b2b-section-second b2b-border2">
                  <div className="op3">
                    <img
                      src="assest/photos/b2b/icon-main/b2b-main-icon2.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <h2 className="b2b-slider-img5">
                      Strategic <br />
                      Partnerships
                    </h2>
                  </div>
                </div>
                <div className="col-6 col-lg-2  b2b-section-second  ">
                  <div className="op3">
                    <img
                      src="assest/photos/b2b/icon-main/b2b-main-icon1.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <h2 className="b2b-slider-img5">
                      Global Customer
                      <br /> Acquisition
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* b2b second section */}
        {/* our client start */}
        <section className="b2b-our-client-section">
          <div className="col-12">
            <motion.h2
              className="slider-heading"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Our Clientele
            </motion.h2>
          </div>
          <div className="container-fluid">
            <div>
              <Slider {...settings4}>
                <NavLink to="/eco-life">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/eco-life.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                  </div>
                </NavLink>
                <NavLink to="/cms">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/cms.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                  </div>
                </NavLink>
                <NavLink to="/jayabheri">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/jaya.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                  </div>
                </NavLink>
                <NavLink to="/performax">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/performax.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                  </div>
                </NavLink>
                <NavLink to="/dirak">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/dirak.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                  </div>
                </NavLink>
                <NavLink to="/signature">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/signature.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                  </div>
                </NavLink>
                <NavLink to="/shodhana">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/shodhana.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                  </div>
                </NavLink>
                <NavLink to="/ramky">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/ramky.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* our client end */}
        {/* b2b section hover-effect section start */}
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <motion.h2
                  className="slider-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Industries where we Excel
                </motion.h2>
              </div>
              <div className="col-12 col-md-4 col-lg-4 b2b-industrial-div">
                <img
                  className="img-fluid w-100 b2b-industrial"
                  src="assest/photos/b2b/b2b-industrial-img1.png"
                  alt="G-Rank"
                  title="b2b"
                />
                <div className="b2b-industrial-text">Power</div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 b2b-industrial-div">
                <img
                  className="img-fluid b2b-industrial"
                  src="assest/photos/b2b/b2b-industrial-img2.png"
                  alt="G-Rank"
                  title="b2b"
                />
                <div className="b2b-industrial-text">Hardware</div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 b2b-industrial-div">
                <img
                  className="img-fluid b2b-industrial"
                  src="assest/photos/b2b/b2b-industrial-img3.png"
                  alt="G-Rank"
                  title="b2b"
                />
                <div className="b2b-industrial-text">Healthcare</div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 b2b-industrial-div">
                <img
                  className="img-fluid b2b-industrial"
                  src="assest/photos/b2b/b2b-industrial-img4.png"
                  alt="G-Rank"
                  title="b2b"
                />
                <div className="b2b-industrial-text">Tech</div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 b2b-industrial-div">
                <img
                  className="img-fluid b2b-industrial"
                  src="assest/photos/b2b/b2b-industrial-img5.png"
                  alt="G-Rank"
                  title="b2b"
                />
                <div className="b2b-industrial-text">Manufacturing</div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 b2b-industrial-div">
                <img
                  className="img-fluid b2b-industrial"
                  src="assest/photos/b2b/b2b-industrial-img6.png"
                  alt="G-Rank"
                  title="b2b"
                />
                <div className="b2b-industrial-text">Raw Materials</div>
              </div>
            </div>
          </div>
        </section>
        {/* b2b section hover-effect section end*/}

        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div>
              <motion.h2
                className="slider-heading"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Quick Links For You
              </motion.h2>
              <Slider {...settings3}>
                <div className="slider-div2">
                  <h4 className="slider-img2">Branding</h4>
                </div>
                <div className="slider-div2">
                  <h4 className="slider-img2">
                    Creative & <p className="d-block"> Content</p>
                  </h4>
                </div>
                <div className="slider-div2">
                  <h4 className="slider-img2">
                    Organic <p className="d-block"> marketing</p>
                  </h4>
                </div>

                <div className="slider-div2">
                  <h4 className="slider-img2">
                    Performance <p className="d-block"> marketing</p>
                  </h4>
                </div>
                <div className="slider-div2">
                  <h4 className="slider-img2">Social Media</h4>
                </div>
                <div className="slider-div2">
                  <h4 className="slider-img2">
                    Web <p className="d-block"> Development</p>
                  </h4>
                </div>
                <div className="slider-div2">
                  <h4 className="slider-img2">Domain</h4>
                </div>
                <div className="slider-div2">
                  <h4 className="slider-img2">
                    Web <p className="d-block"> Hosting</p>{" "}
                  </h4>
                </div>
                <div className="slider-div2">
                  <h4 className="slider-img2">Security</h4>
                </div>
                <div className="slider-div2">
                  <h4 className="slider-img2">Email</h4>
                </div>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        {/* Black-section-div start*/}
        <section>
          <div className="footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/banner-footer32.png"
              alt="G-Rank"
              title="b2b"
            />
            <div className="footer-div-text">
              <motion.p
                initial={{ opacity: 0, scale: 0, y: 100 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                Are you ready to boost
                <br /> your business ?
              </motion.p>
              <NavLink to="">
                <motion.p
                  className="footer-div-buttom"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                  onClick={handleOpen}
                >
                  Contact Us <ArrowRightAltIcon />
                </motion.p>
              </NavLink>
            </div>
          </div>
        </section>
        {/* Black-section-div end*/}
      </section>
    </>
  );
};

export default B2B;
